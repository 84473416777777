import React from 'react';
import { Form } from 'react-bootstrap';

const SelectField = ({ label, name, value, onChange, options, errorMessage }) => (
  <Form.Group controlId={`form${name}`} className="mb-2">
    <Form.Label className="text-muted mb-0">{label}</Form.Label>
    <Form.Select name={name} value={value} onChange={onChange}>
      {options.map(option => (
        <option key={option.id} id={option.id} value={option.name}>{option.name}</option>
      ))}
    </Form.Select>
    {errorMessage && <span className="alert-text">{errorMessage}</span>}
  </Form.Group>
);

export default SelectField;