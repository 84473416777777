import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import currentUser from '../../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../../common/wrappers/detailsWrapper.component';
import { useAddTemplateMutation } from '../../../services/templateApi';
import TextareaField from '../../../common/forms/textarea.form';
import InputField from '../../../common/forms/input.form';
import { SaveIcon } from '../../../common/icons';

const TemplateAdd = () => {
  const history = useHistory()
  const [addTemplate, { isLoading: isAddLoading, isError: isAddError, error }] = useAddTemplateMutation();

  const [formData, setFormData] = useState({
    title: '',
    data: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      const trimmedFormData = {};
      for (const key in formData) {
        trimmedFormData[key] =
          formData[key]?.trim ? formData[key].trim() : formData[key];
      }
      const result = await addTemplate(trimmedFormData);
      if (!result.error) history.push('/templates');
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData?.title?.trim()) {
      errors.title = 'Title is required';
    }
    if (!formData?.data?.trim()) {
      errors.data = 'Text is required';
    }
    return errors;
  };

  const AddButtons = () => (
    <div className="back-button-container p-0 mb-2">
      <Button variant="primary ms-3 w150" type="submit" onClick={handleSubmit}>
        <SaveIcon /> {isAddLoading ? 'Loading...' : 'Add Template'}
      </Button>
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/templates')}>
        Cancel
      </Button>
      {isAddError && <div className="aler-text ms-2" role="alert">
        Error: {error?.data?.errors?.length ? error.data.errors.join(' '): error?.data?.message}
      </div>}
    </div>
  )

  return (
    <DetailsWrapper title="Template" label="Add" blockTitle="Add">
      <Form className="mb-6" onSubmit={handleSubmit}>
        <InputField
          label="Title*"
          name="title"
          value={formData.title}
          onChange={handleChange}
          errorMessage={errors?.title}
          autoFocus
        />
        <TextareaField
          label="Text*"
          name="data"
          value={formData.data}
          onChange={handleChange}
          errorMessage={errors?.data}
          minHeight="400px"
          maxHeight="500px"
        />
      </Form>
      <AddButtons />
    </DetailsWrapper>
  );
};

export default currentUser(TemplateAdd);