import { createApi } from '@reduxjs/toolkit/query/react';
import { setMe } from '../reducers/meReducer';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    getMe: builder.query({
      query: (userID) => ({
        url: `users/${userID}`
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        const response = await queryFulfilled;
        if (response?.data) {
          localStorage.setItem('role', JSON.stringify(response?.data?.role));
          dispatch(setMe(response.data))
        }

      },
    }),
    getUsersList: builder.query({
      query: () => ({
        url: `users?archived=false`
      }),
    }),
    getUserData: builder.query({
      query: (userID) => ({
        url: `users/${userID}`
      }),
    }),
    editUser: builder.mutation({
      query: ({ userID, body }) => ({
        url: `users/${userID}`,
        method: 'PUT',
        body,
      }),
    }),
    addUser: builder.mutation({
      query: (body) => ({
        url: `users`,
        method: 'POST',
        body,
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ userID, body }) => ({
        url: `users/${userID}`,
        method: 'DELETE',
        body,
      }),
    })
  }),
});

export const {
  useGetMeQuery,
  useGetUsersListQuery,
  useGetUserDataQuery,
  useEditUserMutation,
  useAddUserMutation,
  useDeleteUserMutation,
} = userApi;