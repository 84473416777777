import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import currentUser from '../../HOCs/currentUser.hoc';
import ConfirmationModal from '../../common/modals/confirmation.modal';
import FilesTable from './filesTable.component';
import { useUploadFileMutation, useGetFileStatusMutation, useGetFilesQuery, useDeleteFileMutation } from '../../services/fileApi';
import { getAccessToken } from '../../reducers/authReducer';
import { fileStatusUpdated } from '../../reducers/filesReducer';
import { apiRoot } from '../../config';
import { UploadIcon } from '../../common/icons';

import './files.css';

const FilesPage = ({ currentUser }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(getAccessToken);
  const userID = currentUser?.id;
  const filesData = useSelector(state => state.files.files);
  const currentUserRole = currentUser?.role?.name;
  const isAdmin = (currentUserRole?.includes('admin') || currentUserRole?.includes('superadmin'));

  const { isLoading: isFilesLoading, refetch } = useGetFilesQuery({ userID, isAdmin }, { refetchOnMountOrArgChange: true });
  const [uploadFile, { isLoading, isError, error }] = useUploadFileMutation();
  const [getFileStatus, { isLoading: isLoadingStatus }] = useGetFileStatusMutation();
  const [deleteFile] = useDeleteFileMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileDeletingID, setFileeDeletingID] = useState(false);
  const [fileDeletingError, setFileDeletingError] = useState(false);

  const onDelete = (templateID) => {
    setFileeDeletingID(templateID);
    setShowDeleteModal(true);
    setFileDeletingError(false);
  }

  const onHideDelete = () => {
    setFileeDeletingID(false);
    setShowDeleteModal(false);
    setFileDeletingError(false);
  }

  const onConfirmDelete = async () => {
    if (fileDeletingID) {
      const result = await deleteFile(fileDeletingID);
      if (!result.error) {
        await refetch();
        onHideDelete();
      } else {
        setFileDeletingError(result?.error?.data?.message);
      }
    } else {
      onHideDelete();
    }
  }

  const handleFileChange = async (event) => {
    await handleUpload(event.target.files[0])
  };

  const handleUpload = async (selectedFile) => {
    if (!selectedFile) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('audio', selectedFile);

    try {
      await uploadFile(formData);
      refetch();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleCheckStatus = async (file) => {
    if (!file?.id) { return }

    try {
      const response = await getFileStatus(file?.id);
      if (response.data.status === 'complete') {
        dispatch(fileStatusUpdated({ ...file, ...response.data }));
      }
    } catch (error) {
      console.error('Error check status file:', error);
    }
  };

  const handleDownload = async (file) => {
    if (!file?.id) { return }

    try {
      const filename = file.id + '-transcription-' + file.original_file_name.replace(/\.[^.]+$/, '.txt');
      const response = await fetch(`${apiRoot}files/download/${file.id}`, {
        headers: {
          Authorization: accessToken
        }
      });
      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Files <span className="fs-6">Show</span></h3></div>
      <div className="row bg-white">
        <label htmlFor="fileInput" className="btn btn-success w200 ms-2 mt-2 mb-1">
          {isLoading ? 'Uploading...' : <><UploadIcon /> Upload new file</>}
          <input
            id="fileInput"
            type="file"
            accept="audio/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </label>
      </div>
      {isError && <div className="aler-text" role="alert">Error: {error?.data?.message}</div>}
      <FilesTable
        files={filesData}
        onCheckStatus={handleCheckStatus}
        onDownload={handleDownload}
        onDelete={onDelete}
        isLoading={isFilesLoading}
        isAdmin={isAdmin}
        isLoadingStatus={isLoadingStatus}
      />
      <ConfirmationModal
        show={showDeleteModal}
        onHide={onHideDelete}
        onConfirm={onConfirmDelete}
        title="Delete File?"
        description={`Are you sure you want to delete File (ID=${fileDeletingID}) ?`}
        error={fileDeletingError}
      />
    </div>
  );
};

export default currentUser(FilesPage);
