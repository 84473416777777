const formatDateTime = (date) => {
  if (!date) return '---';

  const uploadDate = new Date(date);

  const year = uploadDate.getFullYear();
  const month = ('0' + (uploadDate.getMonth() + 1)).slice(-2);
  const day = ('0' + uploadDate.getDate()).slice(-2);
  const hours = ('0' + uploadDate.getHours()).slice(-2);
  const minutes = ('0' + uploadDate.getMinutes()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export default formatDateTime;
