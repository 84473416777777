import React from 'react';
import TableWrapper from '../../common/table/tableWrapper.common';
import TableHeader from '../../common/table/tableHeader.common';
import { DownloadIcon, DeleteIcon } from '../../common/icons';
import formatDateTime from '../../utils/formatDateTime';

function FilesTable({ files, onCheckStatus, onDownload, isLoading, isAdmin, onDelete, isLoadingStatus }) {
  const userHedares = ['ID', 'File Name', 'Status', 'Upload Date', 'Actions'];
  const adminHeaders = ['ID', 'File Name', 'User', 'Status', 'Upload Date', 'Actions'];
  const headers = isAdmin ? adminHeaders : userHedares;

  const fileTableBody = () => (
    <tbody>
      {files?.map(file => (
        <tr key={file.id} style={{ verticalAlign: 'middle' }}>
          <td>{file.id}</td>
          <td>{file.original_file_name}</td>
          {isAdmin ? <td>{file?.upload_user_name}</td> : null}
          <td>{file.status}</td>
          <td>{formatDateTime(file.upload_date)}</td>
          <td>
            <div className={isAdmin ? "f-t-actions" : ""}>
              {file.status === 'pending' ? (
                <button className="btn btn-info text-light w120 p-b-file-table" onClick={() => onCheckStatus(file)}>
                  {isLoadingStatus ? 'Loading...' : 'Check Status' }
                </button>
              ) : (
                <button className="btn btn-primary w120 p-b-file-table" onClick={() => onDownload(file)}>
                  <DownloadIcon /> Download
                </button>
              )}
              {isAdmin && <div className="cursor-p" onClick={() => onDelete(file.id)} title="Delete">
                <DeleteIcon width="20" height="20" />
              </div>}
            </ div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const fileTableLoader = () => (
    <tbody>
      <tr>
        <td colSpan={isAdmin ? 6 : 5} className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  );

  return (
    <TableWrapper>
      <TableHeader headers={headers} />
      {isLoading ? fileTableLoader() : fileTableBody()}
    </TableWrapper>
  );
}

export default FilesTable;