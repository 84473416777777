import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userID: localStorage.getItem('userID') || '',
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData(state, action) {
      const { accessToken, refreshToken, id } = action.payload;
      state.userID = id;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      
    },
    logout(state) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userID');
      localStorage.removeItem('role');
      localStorage.removeItem('language');
      localStorage.removeItem('call-language');
      localStorage.removeItem('call-code');
      localStorage.removeItem('filters');
      localStorage.removeItem('sendFilters');
      localStorage.removeItem('page');
      localStorage.removeItem('limit');
      localStorage.removeItem('showFilters');
      state.userID = null;
      state.accessToken = null;
      state.refreshToken = null;
    },

  },
});

export const { setAuthData, logout } = authSlice.actions;
export const getAccessToken = (state) => state?.auth?.accessToken;
export default authSlice;