import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import { apiRoot } from '../../config';
import { getAccessToken } from '../../reducers/authReducer';
import { useGetTemplatesListQuery, useDeleteTemplateMutation } from '../../services/templateApi';
import TemplatesTable from './templatesTable.component';
import ConfirmationModal from '../../common/modals/confirmation.modal';
import { AddFileIcon } from '../../common/icons';

import './templates.css';

const TemplatesPage = ({ currentUser }) => {
  const history = useHistory();
  const accessToken = useSelector(getAccessToken);

  const { data, isLoading: isFilesLoading, refetch } = useGetTemplatesListQuery(null, { refetchOnMountOrArgChange: true });
  const [deleteTemplate] = useDeleteTemplateMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateDeletingID, setTemplateDeletingID] = useState(false);
  const [templateDeletingError, setTemplateDeletingError] = useState(false);

  const onView = (templateID) => {
    history.push(`/templates/view/${templateID}`);
  }

  const onEdit = (templateID) => {
    history.push(`/templates/edit/${templateID}`);
  }

  const onDelete = (templateID) => {
    setTemplateDeletingID(templateID);
    setShowDeleteModal(true);
    setTemplateDeletingError(false);
  }

  const onHideDelete = () => {
    setTemplateDeletingID(false);
    setShowDeleteModal(false);
    setTemplateDeletingError(false);
  }

  const onConfirmDelete = async () => {
    if (templateDeletingID) {
      const result = await deleteTemplate(templateDeletingID);
      if (!result.error) {
        await refetch();
        onHideDelete();
      } else {
        setTemplateDeletingError(result?.error?.data?.message);
      }
    } else {
      onHideDelete();
    }
  }

  const handleDownload = async (template) => {
    if (!template?.id) return;

    try {
      const filename = template.id + '-template-' + template?.title + '.txt';
      const response = await fetch(`${apiRoot}templates/download/${template.id}`, {
        headers: {
          Authorization: accessToken
        }
      });
      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading template:', error);
    }
  };

  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Templates <span className="fs-6">Show</span></h3></div>
      <div>
        <div className="row bg-white">
          <button className="btn btn-success w200 ms-2 mt-2 mb-1" onClick={() => history.push('/templates/add')}>
            <AddFileIcon /> Add template
          </button>
          <button className="btn btn-success w200 ms-2 mt-2 mb-1" onClick={() => history.push('/templates/add_v3')}>
            <AddFileIcon /> Add template V3
          </button>
        </div>
      </div>
      <TemplatesTable
        templates={data}
        isLoading={isFilesLoading}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
        onDownload={handleDownload}
        currentUser={currentUser}
      />
      <ConfirmationModal
        show={showDeleteModal}
        onHide={onHideDelete}
        onConfirm={onConfirmDelete}
        title="Delete Template?"
        description={`Are you sure you want to delete Template (ID=${templateDeletingID}) ?`}
        error={templateDeletingError}
      />
    </div>
  );
};

export default currentUser(TemplatesPage);
