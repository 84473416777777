import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import CallTable from './callTable.component';
import { useGetCallsListQuery, useDeleteCallMutation } from '../../services/callApi';
import ConfirmationModal from '../../common/modals/confirmation.modal';
import { RefreshIcon } from '../../common/icons';

const CallsAdminPage = () => {
  const { data, isLoading, refetch } = useGetCallsListQuery(null, { refetchOnMountOrArgChange: true });
  const [deleteCall] = useDeleteCallMutation();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [callDeletingID, setCallDeletingID] = useState(false);
  const [callDeletingError, setCallDeletingError] = useState(false);

  const refreshList = async () => {
    await refetch();
  }

  const onView = (callID) => {
    history.push(`/calls/${callID}`);
  }

  const onDelete = (callID) => {
    setCallDeletingID(callID);
    setShowDeleteModal(true);
    setCallDeletingError(false);
  }

  const onDownload = async (call) => {
    if (call?.record) {
      const response = await fetch(call?.record);

      if (response.ok) {
          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = `${call?.id}_${call?.phone}_record.mp3`;
          document.body.appendChild(link);
          link.click();
          link.remove();
      } else {
          console.log('Failed to download the file.');
      }
    }  
  }

  const onHideDelete = () => {
    setCallDeletingID(false);
    setShowDeleteModal(false);
    setCallDeletingError(false);
  }

  const onConfirmDelete = async () => {
    if (callDeletingID) {
      const result = await deleteCall(callDeletingID);
      if (!result.error) {
        onHideDelete();
        await refetch();
      } else {
        setCallDeletingError(result?.error?.data?.message);
      }
    } else {
      onHideDelete();
    }
  }

  return (
    <div className="main-content mt-5">
      <div className="row text-muted "><h3>Calls <span className="fs-6">List</span></h3></div>
      <div className="row bg-white">
        <button className="btn btn-success w200 ms-2 mt-2 mb-1" onClick={refreshList}>
          <RefreshIcon /> {isLoading ? 'Loading...' : 'Refresh list'}
        </button>
      </div>
      <CallTable calls={data} isLoading={isLoading} onView={onView} onDelete={onDelete} onDownload={onDownload} />
      <ConfirmationModal
        show={showDeleteModal}
        onHide={onHideDelete}
        onConfirm={onConfirmDelete}
        title="Delete Call?"
        description={`Are you sure you want to delete Call (ID=${callDeletingID}) ? The conversation associated with call will also be deleted.`}
        error={callDeletingError}
      />
    </div>
  )
}

export default currentUser(CallsAdminPage);