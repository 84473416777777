import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  first_name: '',
  last_name: '',
  full_name: '',
  email: '',
  role: JSON.parse(localStorage.getItem('role')) || '',
  label: '',
  notes: '',
};

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setMe(state, action) {
      const { id, first_name, last_name, email, role, label, notes } = action.payload;
      state.id = id;
      state.first_name = first_name;
      state.last_name = last_name;
      state.full_name = first_name+' '+last_name;
      state.email = email;
      state.role = role;
      state.label = label;
      state.notes = notes;
    },
    resetMeState(state) {
      state = initialState;
    }
  },
});

export const { setMe, resetMeState } = meSlice.actions;
export default meSlice;