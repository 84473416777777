import React, { useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import currentUser from '../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../common/wrappers/detailsWrapper.component';
import { Button } from 'react-bootstrap';
import { useGetCallByIDQuery, useGetConversationDataQuery } from '../../services/callApi';
import formatDateTime from '../../utils/formatDateTime';

import './conversation.css';

const Conversation = () => {
  const history = useHistory()
  const { callID } = useParams();
  const { data: call, isLoading: isCallsLoading, isError: isCallError, refetch: callRefetch } = useGetCallByIDQuery(callID, { refetchOnMountOrArgChange: true });
  const { data: conversation, isLoading: isConvLoading, isError: isConvError, refetch: convRefech } = useGetConversationDataQuery(callID, { refetchOnMountOrArgChange: true });

  const convDotRef = useRef(null);

  useEffect(() => {
    if (convDotRef.current) {
      convDotRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    let timer;
    if (call && call?.status === 'in-progress') {
      timer = setInterval(async () => {
        await callRefetch();
        await convRefech();
        setTimeout(() => {
          if (convDotRef.current) {
            convDotRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }, 500);
      }, 5000);
    } else {
      timer && clearInterval(timer);
    }

    return () => timer && clearInterval(timer);
  }, [call, callRefetch, convRefech]);

  const BackButton = () => (
    <div className="conv-back-button-container p-0 mb-2">
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/calls')}>
        Back
      </Button>
    </div>
  )

  if (isCallsLoading || isConvLoading) {
    return (
      <DetailsWrapper title="Calls" label="Show" blockTitle="Details">
        <div>Loading...</div>;
        <BackButton />
      </DetailsWrapper>
    )
  }

  if (isCallError || isConvError) {
    return (
      <DetailsWrapper title="Calls" label="Show" blockTitle="Details">
        <div>Error fetching call data</div>
        <BackButton />
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper
      title="Call"
      label="Show"
      blockTitle={<>
        Details <div className="call-status">Status: <span className={call?.status === 'completed' ? "call-status-ok" : 'call-status-progress'}>{call?.status}</span></div>
      </>}
    >
      <div>
        <div className="conv-messages">
          {conversation?.map(conv => (
            <div key={conv?.id} className={`message ${conv?.role === 'bot' ? 'bot-message' : 'user-message'}`}>
              <div className="chat-messages-role">{conv.role}{conv?.role === 'user' && call?.user_name && ` (${call?.phone || call?.user_name})`}</div>
              <div className="text">{conv.message}</div>
              <div className="chat-messages-time">{formatDateTime(conv.time)}</div>
            </div>
          ))}
          <div className="conv-dot-main" ref={convDotRef} >
            {call && call?.status === 'in-progress' ? <div className="conv-dot-container">
              <div className="conv-dot"></div>
              <div className="conv-dot"></div>
              <div className="conv-dot"></div>
            </div> : null}
          </div>
        </div>
      </div>
      <BackButton />
    </DetailsWrapper>
  );
};

export default currentUser(Conversation);