import { createApi } from '@reduxjs/toolkit/query/react';
import { getFilesFulfilled } from '../reducers/filesReducer';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (formData) => ({
        url: 'files',
        method: 'POST',
        body: formData,
      }),
    }),
    getFileStatus: builder.mutation({
      query: (id) => ({
        url: `files/${id}`,
        method: 'GET',
      }),
    }),
    getFiles: builder.query({
      query: ({userID, isAdmin }) => ({
        url: isAdmin ? 'files' : `files?user_id=${userID}`
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        const response = await queryFulfilled;
        if (response?.data) {
          dispatch(getFilesFulfilled(response.data))
        }
      },
    }),
    deleteFile: builder.mutation({
      query: (fileID) => ({
        url: `files/${fileID}`,
        method: 'DELETE',
      }),
    })
  }),
});

export const { useUploadFileMutation, useGetFileStatusMutation, useGetFilesQuery, useDeleteFileMutation } = fileApi;