import React from 'react';
import { Form } from 'react-bootstrap';

const InputField = ({ label, name, value, onChange, errorMessage, autoFocus }) => (
  <Form.Group controlId={`form${name}`} className="mb-2">
    <Form.Label className="text-muted mb-0">{label}</Form.Label>
    <Form.Control type="text" name={name} value={value} onChange={onChange} autoFocus={autoFocus} />
    {errorMessage && <span className="alert-text">{errorMessage}</span>}
  </Form.Group>
);

export default InputField;
