import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const templateApi = createApi({
  reducerPath: 'templateApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    getTemplatesList: builder.query({
      query: () => ({
        url: `templates`
      }),
    }),
    getTemplate: builder.query({
      query: (templateID) => ({
        url: `templates/${templateID}`
      }),
    }),
    getTemplateWithData: builder.query({
      query: (templateID) => ({
        url: `templates/${templateID}?withData=true`
      }),
    }),
    v3AddTemplate: builder.mutation({
      query: (body) => ({
        url: `templates/create-with-audio`,
        method: 'POST',
        body,
      }),
    }),
    addTemplate: builder.mutation({
      query: (body) => ({
        url: `templates/`,
        method: 'POST',
        body,
      }),
    }),
    editTemplate: builder.mutation({
      query: ({ templateID, body }) => ({
        url: `templates/${templateID}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteTemplate: builder.mutation({
      query: (templateID) => ({
        url: `templates/${templateID}`,
        method: 'DELETE',
      }),
    })
  }),
});

export const {
  useGetTemplatesListQuery,
  useGetTemplateQuery,
  useGetTemplateWithDataQuery,
  useAddTemplateMutation,
  useV3AddTemplateMutation,
  useEditTemplateMutation,
  useDeleteTemplateMutation,
} = templateApi;