import { createSlice } from '@reduxjs/toolkit';

const rolesSlice = createSlice({
  name: 'roles',
  initialState: [],
  reducers: {
    setRoles(state, action) {
      state.splice(0, state.length, ...action.payload);
    },
  },
});

export const { setRoles } = rolesSlice.actions;
export default rolesSlice;