import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CallIcon } from '../../common/icons';
import TextareaField from '../../common/forms/textarea.form';
import { useInitCallMutation } from '../../services/callApi';

const LocalCallComponent = () => {
  const [phone, setPhone] = useState('');
  const [template, setTemplate] = useState('');

  const [initCall, { isError }] = useInitCallMutation();

  const onChangePhone = (e) => {
    setPhone(e.target.value);
  }

  const onChangeTemplate = (e) => {
    setTemplate(e.target.value);
  }

  const handleCall = async () => {
    if (!phone?.length || !template?.length) return;
    try {
      const response = await fetch('https://dialbot.aibert.cloud/api/v1/test-local-call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone: phone,
          template: template,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Response:', data);
    } catch (error) {
      console.error('Ошибка вызова API:', error);
    }
  }

  return (
    <div className="speech-container">
      <div className="bot-select mt-2 mb-3"><span>{`Phone: `}</span>
        <input
          type="phone"
          className={`form-control ms-2 mt-1 w243`}
          placeholder="Phone is required"
          value={phone}
          onChange={onChangePhone}
          autoFocus
        />
      </div>

      <div className="bot-select textarea-demo-call">
        <TextareaField
          label="Template: "
          name="data"
          value={template}
          onChange={onChangeTemplate}
          minHeight="400px"
          maxHeight="500px"
        />
      </div>
      <div className=" mt-3">
        <Button
          variant="primary"
          className="call-button"
          onClick={handleCall}
          disabled={!phone?.length}
        >
          <CallIcon className="mb-1 call-button-icon" /> Request a call
        </Button>
      </div>
      <br />
    </div>
  );
};

export default LocalCallComponent;
