import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiRoot } from '../config';

const BASE_URL = apiRoot;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: 'auth/login',
        method: 'POST',
        body: { email, password },
      }),
      transformResponse: (response) => {
        const { accessToken, refreshToken, id } = response;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userID', id);
        return response;
      }
    }),
  }),
});

export const { useLoginMutation } = authApi;