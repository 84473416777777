import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import currentUser from '../../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../../common/wrappers/detailsWrapper.component';
import { Button } from 'react-bootstrap';
import { useGetUserDataQuery } from '../../../services/userApi';
import formatDateTime from '../../../utils/formatDateTime';

const UserView = () => {
  const history = useHistory()
  const { userID } = useParams();
  const { data: user, isLoading, isError } = useGetUserDataQuery(userID, { refetchOnMountOrArgChange: true });

  const BackButton = () => (
    <div className="back-button-container p-0 mb-2">
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/users')}>
        Back
      </Button>
    </div>
  )

  if (isLoading) {
    return (
      <DetailsWrapper title="User" label="Show" blockTitle="Details">
        <div>Loading...</div>;
        <BackButton />
      </DetailsWrapper>
    )
  }

  if (isError) {
    return (
      <DetailsWrapper title="User" label="Show" blockTitle="Details">
        <div>Error fetching user data</div>
        <BackButton />
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper title="User" label="Show" blockTitle="Details">
      <div>
        <p><span className="text-muted">ID:</span> {user?.id}</p>
        <p><span className="text-muted">First Name:</span> {user?.first_name}</p>
        <p><span className="text-muted">Last Name:</span> {user?.last_name}</p>
        <p><span className="text-muted">Email:</span> {user?.email}</p>
        <p><span className="text-muted">Role:</span> {user?.role?.name}</p>
        <p><span className="text-muted">Label:</span> {user?.label}</p>
        <p><span className="text-muted">Notes:</span> {user?.notes}</p>
        <p><span className="text-muted">Registration Date:</span> {formatDateTime(user?.registration_date)}</p>
      </div>
      <BackButton />
    </DetailsWrapper>
  );
};

export default currentUser(UserView);