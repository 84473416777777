import { createApi } from '@reduxjs/toolkit/query/react';
import { setRoles } from '../reducers/rolesReducer';
import baseQueryWithRefresh from './baseQueryWithRefresh';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => ({
        url: 'roles'
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        const response = await queryFulfilled;
        if (response?.data) {
          dispatch(setRoles(response.data));
        }
      },
    }),
  }),
});

export const { useGetRolesQuery } = roleApi;