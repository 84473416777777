import { createSlice } from '@reduxjs/toolkit';

const filesSlice = createSlice({
  name: 'files',
  initialState: {
    files: [],
  },
  reducers: {
    getFilesFulfilled(state, action) {
      state.files = action.payload;
    },
    fileStatusUpdated(state, action) {
      const updatedFile = action.payload;
      const index = state.files.findIndex(file => file.id === updatedFile.id);
      if (index !== -1) {
        state.files[index] = updatedFile;
      }
    },
    resetState(state) {
      state.files = [];
    }
  },
});

export const { getFilesFulfilled, fileStatusUpdated, resetState } = filesSlice.actions;
export default filesSlice;