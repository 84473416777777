import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetUserDataQuery, useEditUserMutation } from '../../../services/userApi';

import currentUser from '../../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../../common/wrappers/detailsWrapper.component';
import InputField from '../../../common/forms/input.form';
import SelectField from '../../../common/forms/select.form';
import TextareaField from '../../../common/forms/textarea.form';
import { SaveIcon } from '../../../common/icons';
import { validateEmail } from '../../../utils/validateEmail';

const UserEdit = () => {
  const history = useHistory()
  const { userID } = useParams();
  const { data: user, isLoading, isError } = useGetUserDataQuery(userID, { refetchOnMountOrArgChange: true });
  const [editUser, { isLoading: isEditLoading, isError: isEditError, error }] = useEditUserMutation();
  const roles = useSelector(state => state.roles);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    role_id: '',
    label: '',
    notes: '',
  });
  const [errors, setErrors] = useState({});
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        role: user?.role?.name,
        role_id: user?.role?.id,
        label: user?.label,
        notes: user?.notes
      });
    }
  }, [user]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setFormChanged(true);
  };

  const handleChangeRole = e => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];
    setFormData(prevState => ({
      ...prevState,
      role: value,
      role_id: id,
    }));
    setFormChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      const trimmedFormData = {};
      for (const key in formData) {
        trimmedFormData[key] =
          typeof formData[key] === 'string' && formData[key].trim ? formData[key].trim() : formData[key];
      }
      const { role, ...editUserData } = trimmedFormData;
      const result = await editUser({ userID, body: editUserData });
      if (!result.error) history.push('/users');
    } else {
      setErrors(validationErrors);
      setFormChanged(false);
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData?.first_name?.trim()) {
      errors.first_name = 'First Name is required';
    }
    if (!formData?.last_name?.trim()) {
      errors.last_name = 'Last Name is required';
    }
    if (!formData?.email?.trim()) {
      errors.email = 'Email is required';
    } else if (!validateEmail(formData.email)) {
      errors.email = 'Invalid email format';
    }
    if (!formData.role_id) {
      errors.role = 'Role is required';
    }
    return errors;
  };

  const EditButtons = () => (
    <div className="back-button-container p-0 mb-2">
      <Button
        variant={`${!formChanged ? 'secondary' : 'primary'} ms-3 w150`}
        type="submit"
        onClick={handleSubmit}
        disabled={!formChanged}
      >
        <SaveIcon /> {isEditLoading ? 'Loading...' : 'Save Changes'}
      </Button>
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/users')}>
        Cancel
      </Button>
      {isEditError && 
      <div className="aler-text ms-2" role="alert">Error: {error?.data?.message}
      </div>}
    </div>
  )

  if (isLoading) {
    return (
      <DetailsWrapper title="User" label="Edit" blockTitle="Edit">
        <div>Loading...</div>;
        <EditButtons />
      </DetailsWrapper>
    )
  }

  if (isError) {
    return (
      <DetailsWrapper title="User" label="Edit" blockTitle="Edit">
        <div>Error fetching user data</div>
        <EditButtons />
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper title="User" label="Edit" blockTitle="Edit">
      <Form className="mb-6" onSubmit={handleSubmit}>
        <InputField
          label="First Name*"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          errorMessage={errors?.first_name}
          autoFocus
        />
        <InputField
          label="Last Name*"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          errorMessage={errors?.last_name}
        />
        <InputField
          label="Email*"
          name="email"
          value={formData.email}
          onChange={handleChange}
          errorMessage={errors?.email}
        />
        <SelectField
          label="Role*"
          name="role"
          value={formData.role}
          options={roles}
          onChange={handleChangeRole}
          errorMessage={errors?.role}
        />
        <InputField
          label="Label"
          name="label"
          value={formData.label}
          onChange={handleChange}
        />
        <TextareaField
          label="Notes"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
          minHeight="150px"
          maxHeight="200px"
        />
      </Form>
      <EditButtons />
    </DetailsWrapper>
  );
};

export default currentUser(UserEdit);