// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.large-text-container {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
  max-height: 400px;
  border: 1px solid #ccc;
  padding: 10px;
  min-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/templates/templates.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,qBAAqB;EACrB,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".large-text-container {\r\n  overflow: auto;\r\n  white-space: pre-wrap;\r\n  word-wrap: break-word;\r\n  width: 100%;\r\n  max-height: 400px;\r\n  border: 1px solid #ccc;\r\n  padding: 10px;\r\n  min-width: 400px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
