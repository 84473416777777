// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-b-file-table {
  --bs-btn-padding-x: auto !important;
  --bs-btn-padding-y: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/components/fileUpload/files.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,mCAAmC;AACrC","sourcesContent":[".p-b-file-table {\r\n  --bs-btn-padding-x: auto !important;\r\n  --bs-btn-padding-y: auto !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
