import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useGetTemplateWithDataQuery, useEditTemplateMutation } from '../../../services/templateApi';

import currentUser from '../../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../../common/wrappers/detailsWrapper.component';
import InputField from '../../../common/forms/input.form';
import TextareaField from '../../../common/forms/textarea.form';
import { SaveIcon } from '../../../common/icons';

const TemplateEdit = () => {
  const history = useHistory()
  const { templateID } = useParams();
  const { data: template, isLoading, isError } = useGetTemplateWithDataQuery(templateID, { refetchOnMountOrArgChange: true });
  const [editTemplate, { isLoading: isEditLoading, isError: isEditError, error }] = useEditTemplateMutation();

  const [formData, setFormData] = useState({
    title: '',
    data: '',
  });
  const [errors, setErrors] = useState({});
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    if (template) {
      setFormData({
        title: template?.title,
        data: template?.fileData,
      });
    }
  }, [template]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setFormChanged(true);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      const trimmedFormData = {};
      for (const key in formData) {
        trimmedFormData[key] =
          formData[key]?.trim ? formData[key].trim() : formData[key];
      }
      const result = await editTemplate({ templateID, body: trimmedFormData });
      if (!result.error) history.push('/templates');
    } else {
      setErrors(validationErrors);
      setFormChanged(false);
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData?.title?.trim()) {
      errors.title = 'Title is required';
    }
    if (!formData?.data?.trim()) {
      errors.data = 'Text is required';
    }
    return errors;
  };

  const EditButtons = () => (
    <div className="back-button-container p-0 mb-2">
      <Button
        variant={`${!formChanged ? 'secondary' : 'primary'} ms-3 w150`}
        type="submit"
        onClick={handleSubmit}
        disabled={!formChanged}
      >
        <SaveIcon /> {isEditLoading ? 'Loading...' : 'Save Changes'}
      </Button>
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/templates')}>
        Cancel
      </Button>
      {isEditError && 
      <div className="aler-text ms-2" role="alert">Error: {error?.data?.message}
      </div>}
    </div>
  )

  if (isLoading) {
    return (
      <DetailsWrapper title="Template" label="Edit" blockTitle="Edit">
        <div>Loading...</div>;
        <EditButtons />
      </DetailsWrapper>
    )
  }

  if (isError) {
    return (
      <DetailsWrapper title="Template" label="Edit" blockTitle="Edit">
        <div>Error fetching template data</div>
        <EditButtons />
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper title="Template" label="Edit" blockTitle="Edit">
      <Form className="mb-6" onSubmit={handleSubmit}>
      <InputField
          label="Title*"
          name="title"
          value={formData.title}
          onChange={handleChange}
          errorMessage={errors?.title}
          autoFocus
        />
        <TextareaField
          label="Text*"
          name="data"
          value={formData.data}
          onChange={handleChange}
          errorMessage={errors?.data}
          minHeight="400px"
          maxHeight="500px"
        />
      </Form>
      <EditButtons />
    </DetailsWrapper>
  );
};

export default currentUser(TemplateEdit);