import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { CallIcon, InfoIcon } from '../../common/icons';
import Select from 'react-select';
import countriesOptions from './countries.json';
import languages from './languages.json'
import { useInitSipCallMutation, useGetCallStatusByIDMutation } from '../../services/callApi';
import { useGetTemplatesListQuery } from '../../services/templateApi';

const CallComponent = () => {

  const [code, setCode] = useState(JSON?.parse(localStorage.getItem('call-code')) || { label: "United States (1)", value: "1" });
  const [phone, setPhone] = useState('');
  const [voice, setVoice] = useState({ id: 'f', title: 'Female' });
  const [supportedLanguages] = useState(languages);
  const [defaultLanguage, setDefaultLanguage] = useState(JSON?.parse(localStorage.getItem('call-language')) || languages[0]);
  const [template, setTemplate] = useState({});
  const [call, setCall] = useState(false);
  const [callID, setCallID] = useState(false);
  const [callError, setCallError] = useState(false);

  const [initCall, { isError }] = useInitSipCallMutation();
  const [getCallStatus] = useGetCallStatusByIDMutation();
  const { data: templates } = useGetTemplatesListQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (templates && Object.keys(template).length === 0) {
      setTemplate(templates[0]);
    }
  }, [templates, template]);

  const onChangeLanguage = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setDefaultLanguage({ name: id, lang: value });
    localStorage.setItem('call-language', JSON.stringify({ name: id, lang: value }));
  }

  const onChangeTemplate = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setTemplate({ id: id, title: value });
  }

  const onChangeCode = (selectedOption) => {
    setCall(false);
    setCode(selectedOption);
    localStorage.setItem('call-code', JSON.stringify(selectedOption));
  };

  const onChangePhone = (e) => {
    setCall(false);
    setPhone(e.target.value)
  }

  const handleCall = async () => {
    if (!phone?.length || !template?.id) return;

    try {
      const fullPhoneNumber = `sip:${code?.value}${phone}@163.172.67.250:5060`
      const response = await initCall(
        { phone: fullPhoneNumber, template_id: template.id, language: defaultLanguage.lang }
      );
      if (!response.error) {
        setCallError(false);
        setCallID(response?.data?.callID);
        setCall('The request has been sent. Please wait for a call on your phone.');
      } else {
        if(response?.error?.data?.message?.includes('This template is not suitable')) {
          setCallError(response?.error?.data?.message);
        } else {
          setCallError('Something went wrong... Please try again later');
        }
      }
    } catch (error) {
      console.error('Error sending phone to API:', error);
    }
  }

  const onChangeVoice= (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];

    setVoice({ id: id, title: value });
  }

  // check call status
  useEffect(() => {
    let attempts = 0;
    let intervalId;

    const checkCallStatus = async () => {
      try {
        const response = await getCallStatus(callID);
        const status = response?.data?.status;
        if (status === 'requested') {
          attempts++;
          if (attempts <= 4) {
            return;
          } else {
            clearInterval(intervalId);
            setCallID(null);
            setCall('The call is still in queue, we will call you as soon as the bot is free.');
            return;
          }
        } else {
          clearInterval(intervalId);
          setCallID(null);
          switch (status) {
            case 'in-progress':
              setCall('The call has been started.');
              break;
            case 'failed':
              setCall('There were problems when trying to call, please try later.');
              break;
            case 'completed':
              setCall('The call was successfully completed');
              break;
            case 'busy':
              setCall('We called this number, but it is currently busy. Please try again later');
              break;
            default:
              break;
          }
        }
      } catch (error) {
        console.error('Error getting call status:', error);
      }
    };

    if (callID) {
      intervalId = setInterval(checkCallStatus, 4000); // Check call status every 4 seconds
      return () => clearInterval(intervalId);
    }
  }, [callID]);

  return (
    <div className="speech-container">
      <div className="bot-select mb-3 mt-2"><span>Code: </span>
        <Select
          options={countriesOptions}
          onChange={onChangeCode}
          placeholder="Select country code"
          isSearchable
          classNamePrefix="custom-select"
          value={code}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: '275px',
              marginLeft: '5px',
              border: 'var(--bs-border-width) solid var(--bs-border-color)',
              borderRadius: 'var(--bs-border-radius)'
            })
          }}
        />
      </div>
      <div className="bot-select mb-3"><span>{`Phone: (${code?.value}) `}</span>
        <input
          type="phone"
          className={`form-control ms-2 mt-1 ${code?.value?.length == 1 ? 'w243' : 'w225'}`}
          placeholder="Phone is required"
          value={phone}
          onChange={onChangePhone}
          autoFocus
        />
      </div>
      <div className="bot-select mb-3"><span>Language:</span>
        <select className="form-select w240 bot-select ms-2" onChange={onChangeLanguage} value={defaultLanguage?.lang}>
          {supportedLanguages?.map(lang => (
            <option key={lang.lang} id={lang.name} value={lang.lang}>
              {lang.name}
            </option>
          ))}
        </select>
      </div>
      <div className="bot-select mb-3">
        <span>Template:</span>
        <select className="form-select w243 bot-select ms-2" onChange={onChangeTemplate}>
          {templates?.map(temp => (
            <option key={temp.id} id={temp.id} value={temp.title}>
              {temp.title}
            </option>
          ))}
        </select>
      </div>
      <div className="bot-select">
        <span>Voice:</span>
        <select className="form-select w271 bot-select ms-2" onChange={onChangeVoice}>
          {[{ id: 'f', title: 'Female' }]?.map(voice => (
            <option key={voice.id} id={voice.id} value={voice.title}>
              {voice.title}
            </option>
          ))}
        </select>
      </div>
      <div className=" mt-3">
        <Button
          variant="primary"
          className="call-button"
          onClick={handleCall}
          disabled={!phone?.length || callID}
        >
          <CallIcon className="mb-1 call-button-icon"/> {callID ? <div className="d-inline-flex">Requested <div class="call-dot-container">
        <div className="call-dot"></div>
        <div className="call-dot"></div>
        <div className="call-dot"></div>
      </div></div> : 'Request a call'}
        </Button>
      </div>
      <br />
      {call ? <div className="d-flex"><p><InfoIcon /> {call}</p></div> : null}
      {isError ? <><br /><p className="alert-text"><span>Error: </span>{callError}</p></> : null}
      <br />
      <div className="call-info-block p-0 mb-2">
        <p><InfoIcon /> First select the country code, then enter the number (without + and code).</p>
        <p><InfoIcon /> To get started, click on the blue call button. The bot will call you.</p>
      </div>
    </div>
  );
};

export default CallComponent;
