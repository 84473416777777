import React from 'react';
import TableWrapper from '../../common/table/tableWrapper.common';
import TableHeader from '../../common/table/tableHeader.common';
import { ShowIcon, EditIcon, DeleteIcon } from '../../common/icons';
import formatDateTime from '../../utils/formatDateTime';

const UserTable = ({ users, isLoading, onView, onEdit, onDelete }) => {
  const headers = ['ID', 'Full Name', 'Email', 'Role', 'Registration Date', 'Actions'];

  const fileTableBody = () => (
    <tbody>
      {users?.map(user => (
        <tr key={user.id} style={{ verticalAlign: 'middle' }}>
          <td>{user?.id}</td>
          <td>{user?.first_name + ' ' + user?.last_name}</td>
          <td>{user?.email}</td>
          <td>{user?.role?.name}</td>
          <td>{formatDateTime(user?.registration_date)}</td>
          <td>
            <div className="f-t-actions mw-96">
              <div className="cursor-p" onClick={() => onView(user.id)} title="View"><ShowIcon /></div>
              <div className="cursor-p" onClick={() => onEdit(user.id)} title="Edit"><EditIcon /></div>
              <div className="cursor-p" onClick={() => onDelete(user.id)} title="Delete"><DeleteIcon /></div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const fileTableLoader = () => (
    <tbody>
      <tr>
        <td colSpan="6" className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  );

  return (
    <TableWrapper>
      <TableHeader headers={headers} />
      {isLoading ? fileTableLoader() : fileTableBody()}
    </TableWrapper>
  );
}

export default UserTable;