import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../services/authApi';
import { useHistory } from "react-router-dom";
import { setAuthData, logout } from '../../reducers/authReducer';
import { validateEmail } from '../../utils/validateEmail';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();
  const [login, { isLoading, isError, error }] = useLoginMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEmailError('');
    setPasswordError('');

    if (!email) {
      setEmailError('Email is required');
    }
    if (!password) {
      setPasswordError('Password is required');
    }
    if (email && !validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    }

    if (email && password && validateEmail(email)) {
      try {
        const response = await login({ email, password });
        dispatch(setAuthData(response.data));
        history.push("/files");
      } catch (err) {
        dispatch(logout());
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <h3>Sign In</h3>
        <div className="mb-3">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
            onKeyDown={handleKeyPress}
          />
          {!!emailError && <div className="alert-text">{emailError}</div>}
        </div>
        <div className="mb-3">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          {passwordError && <div className="alert-text">{passwordError}</div>}
        </div>
        <div className="d-grid">
          <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
        </div>
        {isError && error ? <div className="empty-block alert-text">{error?.data?.message}</div> : <div className="empty-block"/>}
      </div>
    </div>
  );
};

export default LoginPage;
