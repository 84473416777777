// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f-t-actions {
  display: flex;
}

.f-t-actions > * {
  margin-right: 15%;
}

.f-t-actions > :last-child {
  margin-right: 0;
}

.w200 {
  width: 200px;
}

.user-view-container {
  display: flex;
  flex-direction: column;
}

.back-button-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/usersAdmin/user.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;AACb","sourcesContent":[".f-t-actions {\r\n  display: flex;\r\n}\r\n\r\n.f-t-actions > * {\r\n  margin-right: 15%;\r\n}\r\n\r\n.f-t-actions > :last-child {\r\n  margin-right: 0;\r\n}\r\n\r\n.w200 {\r\n  width: 200px;\r\n}\r\n\r\n.user-view-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.back-button-container {\r\n  display: flex;\r\n  align-items: center;\r\n  position: absolute;\r\n  bottom: 0;\r\n  left: 0;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
