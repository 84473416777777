import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAccessToken } from './reducers/authReducer';
import ProtectedRoute from './HOCs/ProtectedRoute'; 
import ProtectedAdminRoute from './HOCs/ProtectedAdminRouter';
import Menu from './common/menu/menu.common';
import SideMenu from './common/menu/sideMenu.common';
import LoginPage from './components/login/login.component';
import FilesPage from './components/fileUpload/filesPage.component';
import UserAdminPage from './components/usersAdmin/userAdminPage.component';
import UserView from './components/usersAdmin/view/userView.component';
import UserEdit from './components/usersAdmin/edit/userEdit.component'; 
import UserAdd from './components/usersAdmin/add/userAdd.component';
import TemplatesPage from './components/templates/templatesPage.component';
import TemplateAdd from './components/templates/add/templateAdd.component';
import TemplateAddV3 from './components/templates/add/templateAdd_v3.component';
import TemplateView from './components/templates/view/templateView.component';
import TemplateEdit from './components/templates/edit/templateEdit.component';
import VocalPage from './components/vocal/vocalPage.component';
import CallPage from './components/call/callPage.component';
import CallsAdminPage from './components/adminCalls/callsAdminPage.component';
import ConversationPage from './components/adminCalls/conversation.component';
import CallV2Page from './components/call_bland/callPage.component';
import CallV3Page from './components/call_sip/callPage.component';
import BatchCallPage from './components/call_batch/batchCallPage.component';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './call.css';


const App = () => {
  const accessToken = useSelector(getAccessToken);
  const isLoggedIn = !!accessToken;

  const role = useSelector((state) => state?.me?.role?.name);
  const isAdmin = (role?.includes('admin') || role?.includes('superadmin'));

  return (
    <Router>
      <div className="App">
        {isLoggedIn && <SideMenu />}
        {isLoggedIn && <Menu />}
        <Switch>
          <Route exact path="/" render={() => !isLoggedIn ? <Redirect to='/login' /> : <Redirect to='/files' />} />
          <Route path="/login" component={LoginPage} />
          <ProtectedRoute path="/files" component={FilesPage} isAuthenticated={isLoggedIn} />
          <ProtectedAdminRoute path="/users/add" component={UserAdd} isAuthenticated={isLoggedIn} isAdmin={isAdmin} />
          <ProtectedAdminRoute path="/users/view/:userID" component={UserView} isAuthenticated={isLoggedIn} isAdmin={isAdmin} />
          <ProtectedAdminRoute path="/users/edit/:userID" component={UserEdit} isAuthenticated={isLoggedIn} isAdmin={isAdmin} />
          <ProtectedAdminRoute path="/users" component={UserAdminPage} isAuthenticated={isLoggedIn} isAdmin={isAdmin} />
          <ProtectedRoute path="/templates/add" component={TemplateAdd} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/templates/add_v3" component={TemplateAddV3} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/templates/view/:templateID" component={TemplateView} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/templates/edit/:templateID" component={TemplateEdit} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/templates" component={TemplatesPage} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/vocal" component={VocalPage} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/call" component={CallPage} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/call_v2" component={CallV2Page} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/call_v3" component={CallV3Page} isAuthenticated={isLoggedIn} />
          <ProtectedRoute path="/batch-call" component={BatchCallPage} isAuthenticated={isLoggedIn} />
          <ProtectedAdminRoute path="/calls/:callID" component={ConversationPage} isAuthenticated={isLoggedIn} isAdmin={isAdmin} />
          <ProtectedAdminRoute path="/calls" component={CallsAdminPage} isAuthenticated={isLoggedIn} isAdmin={isAdmin} />
        </Switch>
      </div >
    </Router >
  );

}

export default App;