import React from 'react';

const DetailsWrapper = ({ children, title, label, blockTitle }) => (
  <div className="user-view-container main-content mt-5">
    <div className="row text-muted"><h3>{title} <span className="fs-6">{label}</span></h3></div>
    <div className="row d-block bg-white flex-grow-1 t-a-justify position-relative">
      <div className="border-bottom h55 pt-3 pb-2 mb-2 text-muted">
        <h5>{blockTitle}</h5>
      </div>
      {children}
    </div>
  </div >
);

export default DetailsWrapper;