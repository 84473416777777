import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { logout } from '../../reducers/authReducer';
import { resetState } from '../../reducers/filesReducer';
import { resetMeState } from '../../reducers/meReducer';
import { AvatarIcon } from '../icons';

const Menu = () => {
  const fullName = useSelector((state) => state?.me?.full_name);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetState());
    dispatch(resetMeState());
    history.push("/login");
  };

  return (
    <Navbar bg="light" variant="light" fixed="top" className="pe-4 top-menu-m">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="test">
            <NavDropdown title={<span className="me-2"><AvatarIcon />{fullName}</span>} id="user-nav-dropdown">
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  )
};

export default Menu;
