import React from 'react';
import { useSelector } from 'react-redux';
import { useGetMeQuery } from '../services/userApi';
import { useGetRolesQuery } from '../services/roleApi';

const currentUser = (WrappedComponent) => {
  return (props) => {
    const userID = useSelector((state) => state?.auth?.userID);
    const { data, isLoading } = useGetMeQuery(userID, { refetchOnMountOrArgChange: true });
    const { data: roles, isLoading: isRolesLoading } = useGetRolesQuery();

    if (isLoading || isRolesLoading) {
      return <div>Loading...</div>;
    }

    return <WrappedComponent currentUser={data} roles={roles} {...props} />;
  };
};

export default currentUser;