import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import currentUser from '../../../HOCs/currentUser.hoc';
import DetailsWrapper from '../../../common/wrappers/detailsWrapper.component';
import { Button } from 'react-bootstrap';
import { useGetTemplateWithDataQuery } from '../../../services/templateApi';

const TemplateView = () => {
  const history = useHistory()
  const { templateID } = useParams();
  const { data: template, isLoading, isError } = useGetTemplateWithDataQuery(templateID, { refetchOnMountOrArgChange: true });

  const BackButton = () => (
    <div className="back-button-container p-0 mb-2">
      <Button variant="secondary ms-3 w100" onClick={() => history.push('/templates')}>
        Back
      </Button>
    </div>
  )

  if (isLoading) {
    return (
      <DetailsWrapper title="Template" label="Show" blockTitle="Details">
        <div>Loading...</div>;
        <BackButton />
      </DetailsWrapper>
    )
  }

  if (isError) {
    return (
      <DetailsWrapper title="Template" label="Show" blockTitle="Details">
        <div>Error fetching template data</div>
        <BackButton />
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper title="Template" label="Show" blockTitle="Details">
      <div>
        <p><span className="text-muted">ID:</span> {template?.id}</p>
        <p><span className="text-muted">Title:</span> {template?.title}</p>
        <p><span className="text-muted">Text:</span></p>
        <div className="large-text-container">{template?.fileData}</div>
      </div>
      <BackButton />
    </DetailsWrapper>
  );
};

export default currentUser(TemplateView);