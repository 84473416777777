import React from 'react';
import currentUser from '../../HOCs/currentUser.hoc';
import BatchCallComponent from './batcCall.component';

const BatchCallPage = () => {
  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Batch of Cals</h3></div>
      <div className="row bg-white p-2 h90p">
        <BatchCallComponent className="call-button-icon mb-1" />
      </div>
      <div className="empty-bottom-block" />
    </div>
  );
};

export default currentUser(BatchCallPage);
