// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #263444;
  overflow-x: hidden;
  color: #ffffff;
}

.sidebar .header {
  display: flex;
  height: 56px;
  justify-content: center;
  background-color: #1F2B37;
  text-align: center;
  margin-bottom: 10px;
}

.sidebar .header p {
  margin: auto;
  margin-left: 30%;
}

.sidebar .nav-link {
  color: #ffffff;
}

.sidebar .nav-link:hover {
  background-color: #1F2B37;
  color: #ffffff;
}

.sidebar .nav-link.active {
  background-color: #1F2B37;
  color: #ffffff;
  border-left: 2px solid #ffffff;
}

.top-menu-m {
  margin-left: 250px;
  height: 56px;
}

.test .nav-item div {
  right: 0;
  left: -20px;
}`, "",{"version":3,"sources":["webpack://./src/common/menu/menu.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;EACP,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,QAAQ;EACR,WAAW;AACb","sourcesContent":[".sidebar {\r\n  height: 100%;\r\n  width: 250px;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  background-color: #263444;\r\n  overflow-x: hidden;\r\n  color: #ffffff;\r\n}\r\n\r\n.sidebar .header {\r\n  display: flex;\r\n  height: 56px;\r\n  justify-content: center;\r\n  background-color: #1F2B37;\r\n  text-align: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.sidebar .header p {\r\n  margin: auto;\r\n  margin-left: 30%;\r\n}\r\n\r\n.sidebar .nav-link {\r\n  color: #ffffff;\r\n}\r\n\r\n.sidebar .nav-link:hover {\r\n  background-color: #1F2B37;\r\n  color: #ffffff;\r\n}\r\n\r\n.sidebar .nav-link.active {\r\n  background-color: #1F2B37;\r\n  color: #ffffff;\r\n  border-left: 2px solid #ffffff;\r\n}\r\n\r\n.top-menu-m {\r\n  margin-left: 250px;\r\n  height: 56px;\r\n}\r\n\r\n.test .nav-item div {\r\n  right: 0;\r\n  left: -20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
